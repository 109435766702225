// src/App.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Modal from "react-modal";
import "./App.css";
import Controller from "./Controller";
import Output from "./Output";
import SimilarArticlesList from "./SimilarArticlesList"; // Ensure correct import
import SourceSection from "./SourceSection";
import SourcePopup from "./SourcePopup";
import Header from "./Header";
import Placeholder from "./Placeholder";
import Login from "./Login";
import Settings from "./Settings";
import Disclaimer from "./Disclaimer";
import axios from "axios";
import { Analytics } from "@vercel/analytics/react";
import ProtectedRoute from "./ProtectedRoute"; // Ensure ProtectedRoute is correctly imported

function App() {
  const navigate = useNavigate(); // Initialize useNavigate
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [qaList, setQaList] = useState([]);
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(null);
  const [isSourcePopupOpen, setIsSourcePopupOpen] = useState(false);
  const [sourcePopupInfo, setSourcePopupInfo] = useState(null);
  const [isSourceCollapsed, setIsSourceCollapsed] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isError, setIsError] = useState(false);

  const controllerRef = useRef(); // Ref to Controller

  const isSmallScreen = windowWidth < 768;

  const checkUserAccess = useCallback(async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USERS_API}/check_access/${email}`
      );
      return response.data;
    } catch (error) {
      console.error("User access check error:", error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchUserInfoAndCheckAccess = async () => {
      try {
        if (isAuthenticated && !userInfo) {
          const userEmail = localStorage.getItem("userEmail");
          if (userEmail) {
            if (!localStorage.getItem("userInfo")) {
              const response = await axios.get(
                `${
                  process.env.REACT_APP_USERS_API
                }/get_user_info/${encodeURIComponent(userEmail)}`
              );
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              setUserInfo(response.data);
            }

            const accessData = await checkUserAccess(userEmail);
            if (!accessData.access) {
              // Set isLoggedIn to false before redirecting
              localStorage.setItem("isLoggedIn", "false"); // Ensure it's a string
              setIsAuthenticated(false);
              navigate("/login", { replace: true }); // Use navigate instead of window.location.href
            }
          }
        }
      } catch (error) {
        console.error("Error on initial load:", error);
        setIsError(true);
      }
    };

    fetchUserInfoAndCheckAccess();
  }, [isAuthenticated, userInfo, checkUserAccess, navigate]);

  const handleNewQuestion = (question) => {
    const newResponseIndex = qaList.length;
    setQaList((prevList) => [
      ...prevList,
      { question: question, answer: "", sources: [] },
    ]);
    setSelectedResponseIndex(newResponseIndex);

    setIsError(false);
    return newResponseIndex;
  };

  const updateResponse = (index, data, isSource = false) => {
    setQaList((prevList) => {
      const newList = [...prevList];
      if (isSource) {
        newList[index] = { ...newList[index], sources: data };
      } else {
        newList[index] = {
          ...newList[index],
          answer: newList[index].answer + data,
        };
      }
      return newList;
    });
  };

  const openSourcePopup = (sourceInfo) => {
    console.log("Source Info:", sourceInfo); // Debugging line
    setIsSourcePopupOpen(true);
    const currentAnswer =
      selectedResponseIndex !== null
        ? qaList[selectedResponseIndex].answer
        : "";
    setSourcePopupInfo({
      title: sourceInfo.title,
      authors: sourceInfo.authors,
      publicationDate: sourceInfo.date_published,
      publicationType: sourceInfo.publication_types, // Updated key
      abstract: sourceInfo.abstract,
      pmid: sourceInfo.pmid,
      answer: currentAnswer,
    });
  };

  const closeSourcePopup = () => {
    setIsSourcePopupOpen(false);
    setSourcePopupInfo(null);
  };

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (isAuthenticated && !localStorage.getItem("userInfo")) {
          const userEmail = localStorage.getItem("userEmail");
          if (userEmail) {
            const response = await axios.get(
              `${
                process.env.REACT_APP_USERS_API
              }/get_user_info/${encodeURIComponent(userEmail)}`
            );
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            setUserInfo(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        setIsError(true);
      }
    };
    fetchUserInfo();
  }, [isAuthenticated]);

  const authenticateUser = (isAuth) => {
    setIsAuthenticated(isAuth);
    localStorage.setItem("isLoggedIn", isAuth ? "true" : "false"); // Ensure it's a string
    if (!isAuth) {
      // Optionally, clear user-related data on logout
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userInfo");
    }
  };

  // Use useLocation to determine the current path
  const location = useLocation();

  // Define paths where the Controller should be hidden
  const hideControllerRoutes = ["/settings", "/login", "/disclaimer"];

  const isControllerVisible =
    isAuthenticated && !hideControllerRoutes.includes(location.pathname);

  // Determine whether the controller should be hidden based on the state of the source section and screen size
  const hideController =
    (isSmallScreen && !isSourceCollapsed) || isSourcePopupOpen;

  // **Redo Question Function**
  const redoQuestion = (index) => {
    const question = qaList[index].question;
    // Reset the answer and sources for the question
    setQaList((prevList) => {
      const newList = [...prevList];
      newList[index].answer = "";
      newList[index].sources = [];
      return newList;
    });
    // Re-issue the question via Controller
    if (controllerRef.current) {
      controllerRef.current.fetchAnswer(question, index);
    }
  };

  return (
    <div className="App bg-gray-900 min-h-screen flex flex-col">
      <Header />
      <main className="flex-1 flex">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <div className="flex flex-1">
                  <div
                    className={`flex flex-col w-full p-4 transition-width duration-300 space-y-4 ${
                      isSourceCollapsed ? "" : "md:w-3/4"
                    }`}
                  >
                    {qaList.length === 0 ? (
                      <div className="flex-1 flex flex-col justify-center items-center mb-20">
                        <Placeholder />
                      </div>
                    ) : (
                      <>
                        {qaList.map((qa, index) => (
                          <div key={index} className="mb-4">
                            <Output
                              question={qa.question}
                              answer={qa.answer}
                              index={index}
                              setSelectedResponseIndex={
                                setSelectedResponseIndex
                              }
                              redoQuestion={redoQuestion} // Pass redoQuestion
                            />
                          </div>
                        ))}
                        {/* Additional padding below Output so user can scroll */}
                        <div style={{ height: "100px" }} />
                      </>
                    )}
                  </div>
                  <SourceSection
                    sources={
                      selectedResponseIndex !== null
                        ? qaList[selectedResponseIndex].sources
                        : []
                    }
                    openSourcePopup={openSourcePopup}
                    isCollapsed={isSourceCollapsed}
                    setIsCollapsed={setIsSourceCollapsed}
                    selectedResponseIndex={selectedResponseIndex}
                  />
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Settings authenticateUser={authenticateUser} />{" "}
                {/* Pass authenticateUser */}
              </ProtectedRoute>
            }
          />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Login authenticateUser={authenticateUser} />
              )
            }
          />
          {/* Optionally, handle unknown routes */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      {/* Conditionally render Controller based on source popup state and window size */}
      {!hideController && isControllerVisible && (
        <Controller
          ref={controllerRef} // Attach the ref
          handleNewQuestion={handleNewQuestion}
          updateResponse={updateResponse}
          isSourceCollapsed={isSourceCollapsed}
        />
      )}
      <Modal
        isOpen={isSourcePopupOpen}
        onRequestClose={closeSourcePopup}
        className="outline-none" // Remove fixed positioning from Modal
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
        ariaHideApp={false}
      >
        {sourcePopupInfo && (
          <SourcePopup
            {...sourcePopupInfo}
            closeSourcePopup={closeSourcePopup}
            isSourceCollapsed={isSourceCollapsed} // Pass isSourceCollapsed
          />
        )}
      </Modal>
      <Analytics />
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
