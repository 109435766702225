// src/Output.js
import React, { useState } from "react";

const Output = ({
  question,
  answer,
  index,
  setSelectedResponseIndex,
  redoQuestion,
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);

  const stripHtml = (html) => {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const copyToClipboard = async (e) => {
    e.stopPropagation();
    try {
      if (navigator.clipboard && window.ClipboardItem) {
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([answer], { type: "text/html" }),
            "text/plain": new Blob([stripHtml(answer)], { type: "text/plain" }),
          }),
        ]);
      } else {
        const tempEl = document.createElement("div");
        tempEl.contentEditable = "true";
        tempEl.innerHTML = answer;
        document.body.appendChild(tempEl);

        const range = document.createRange();
        range.selectNodeContents(tempEl);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        tempEl.focus();
        document.execCommand("copy");

        document.body.removeChild(tempEl);
        sel.removeAllRanges();
      }
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 1000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleClick = () => {
    setSelectedResponseIndex(index);
  };

  const convertMarkdownLinksToHtml = (text) => {
    return text.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
  };

  const formattedIndex = (index + 1).toString().padStart(2, "0");
  let formattedAnswer = answer.replace(/\n/g, "<br>");
  formattedAnswer = convertMarkdownLinksToHtml(formattedAnswer).replace(
    /\n/g,
    "<br>"
  );

  const handleRedo = (e) => {
    e.stopPropagation();
    redoQuestion(index);
  };

  return (
    <div
      className="relative max-w-2xl mx-auto p-6 border rounded-lg shadow bg-gray-800 border-gray-700"
      onClick={handleClick}
    >
      <div className="flex flex-col items-center">
        <div
          className="self-start mb-2 text-lg font-semibold"
          style={{ color: "#F5E9D2" }}
        >
          #{formattedIndex}
        </div>
        {question && (
          <h5
            className="text-xl font-bold tracking-tight text-white text-center"
            style={{ color: "#F8E8CF" }}
          >
            {question}
          </h5>
        )}
      </div>
      <div
        className="mb-3 font-normal text-gray-400 whitespace-pre-wrap break-words text-left markdown-content"
        dangerouslySetInnerHTML={{ __html: formattedAnswer }}
      />
      {answer && (
        <div className="absolute bottom-4 right-4 flex space-x-2">
          {/* Redo Button */}
          <button
            onClick={handleRedo}
            onMouseEnter={() => setHoveredButton("redo")}
            onMouseLeave={() => setHoveredButton(null)}
            className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-600"
            title="Redo question"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/redo.png`}
              alt="Redo question"
              className="w-5 h-5"
            />
          </button>

          {/* Copy Button */}
          <button
            onClick={copyToClipboard}
            onMouseEnter={() => setHoveredButton("copy")}
            onMouseLeave={() => setHoveredButton(null)}
            className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-600"
            title="Copy to clipboard"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/paste-clipboard.svg`}
              alt="Copy to clipboard"
              className="w-5 h-5"
            />
          </button>

          {/* Tooltip */}
          {hoveredButton === "copy" && (
            <div className="absolute bottom-full mb-2 right-0 p-2 bg-gray-700 text-white text-xs rounded">
              Copy response
            </div>
          )}
          {hoveredButton === "redo" && (
            <div className="absolute bottom-full mb-2 right-0 p-2 bg-gray-700 text-white text-xs rounded">
              Redo question
            </div>
          )}

          {/* Success Message */}
          {showSuccessMessage && (
            <div
              className="absolute -bottom-16 right-0 flex items-center w-250 max-w-xs p-2 text-sm rounded-lg shadow text-gray-400 bg-gray-900"
              role="alert"
            >
              <div className="ms-2">Copied.</div>
              <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-lg text-green-300">
                {/* SVG for success icon */}
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM7.707 9.293a1 1 0 00-1.414 1.414L9 13.414l4.293-4.293a1 1 0 00-1.414-1.414L9 10.586l-1.293-1.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Output;
