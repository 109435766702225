import React, { useState, useEffect, useRef } from "react";
import SimilarArticlesList from "./SimilarArticlesList";

const SourcePopup = ({
  title,
  authors,
  publicationDate,
  publicationType,
  abstract,
  pmid,
  isSourceCollapsed,
  closeSourcePopup,
  answer,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [similarArticles, setSimilarArticles] = useState([]);
  const [isLoadingSimilar, setIsLoadingSimilar] = useState(false);
  const [currentArticle, setCurrentArticle] = useState({
    title,
    authors,
    publicationDate,
    publicationType: Array.isArray(publicationType)
      ? publicationType
      : publicationType
      ? [publicationType]
      : [],
    abstract,
    pmid,
    answer,
  });
  const [sourceHistory, setSourceHistory] = useState([]);
  const [highlightedAbstract, setHighlightedAbstract] = useState(abstract);
  const [isLoadingAbstract, setIsLoadingAbstract] = useState(false);

  const popupRef = useRef(null);

  const pubmedUrl = `https://pubmed.ncbi.nlm.nih.gov/${currentArticle.pmid}/`;

  const generateCitation = () => {
    const publicationTypeString =
      currentArticle.publicationType.length > 0
        ? currentArticle.publicationType.join(", ")
        : "N/A";

    return `${currentArticle.authors}. "${currentArticle.title}" *${publicationTypeString}*, ${currentArticle.publicationDate}. PubMed, ${pubmedUrl}.`;
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(generateCitation().replace(/\*/g, ""))
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy citation: ", err));
  };

  const updateSourcePopup = (article) => {
    setSourceHistory((prevHistory) => [...prevHistory, currentArticle]);
    setCurrentArticle({
      title: article.title,
      authors: article.authors,
      publicationDate: article.date_published,
      publicationType: Array.isArray(article.publication_type)
        ? article.publication_type
        : article.publication_type
        ? [article.publication_type]
        : [],
      abstract: article.abstract,
      pmid: article.pmid,
      answer,
    });
    fetchSimilarArticles(article.pmid);
    setHighlightedAbstract(article.abstract); // Reset highlighted abstract
  };

  const fetchSimilarArticles = (pmid) => {
    setIsLoadingSimilar(true);
    setSimilarArticles([]);
    fetch(`${process.env.REACT_APP_API_URL}/similar_articles/${pmid}?retmax=10`)
      .then((response) => response.json())
      .then((data) => {
        setSimilarArticles(data.similar_articles);
        setIsLoadingSimilar(false);
      })
      .catch((error) => {
        console.error("Error fetching similar articles:", error);
        setIsLoadingSimilar(false);
      });
  };

  useEffect(() => {
    fetchSimilarArticles(currentArticle.pmid);
  }, [currentArticle.pmid]);

  useEffect(() => {
    setCurrentArticle({
      title,
      authors,
      publicationDate,
      publicationType: Array.isArray(publicationType)
        ? publicationType
        : publicationType
        ? [publicationType]
        : [],
      abstract,
      pmid,
      answer,
    });
    setSourceHistory([]);
    setHighlightedAbstract(abstract);
  }, [
    title,
    authors,
    publicationDate,
    publicationType,
    abstract,
    pmid,
    answer,
  ]);

  const handleBack = () => {
    if (sourceHistory.length > 0) {
      const previousArticle = sourceHistory[sourceHistory.length - 1];
      setSourceHistory((prevHistory) => prevHistory.slice(0, -1));
      setCurrentArticle(previousArticle);
      fetchSimilarArticles(previousArticle.pmid);
      setHighlightedAbstract(previousArticle.abstract);
    }
  };

  // Fetch highlight phrases and update the abstract
  useEffect(() => {
    const fetchHighlightPhrases = async () => {
      setIsLoadingAbstract(true); // Start loading
      try {
        console.log("Fetching highlight phrases...");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/get_highlight_phrases`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              answer: currentArticle.answer,
              abstract: currentArticle.abstract,
            }),
          }
        );
        const data = await response.json();
        console.log("Received data:", data);
        const phrases = data.phrases || [];

        let newAbstract = currentArticle.abstract;
        // For each phrase, replace the matched text with highlighted text
        phrases.forEach((phrase) => {
          if (!phrase) return; // Skip empty phrases
          // Escape special characters in the phrase
          const escapedPhrase = phrase.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
          try {
            // Create a regex from the escaped phrase
            const regex = new RegExp(escapedPhrase, "gi");
            newAbstract = newAbstract.replace(
              regex,
              (match) =>
                `<mark class="mark-custom mark-rounded">${match}</mark>`
            );
          } catch (e) {
            console.error(`Invalid regex pattern for phrase: ${phrase}`, e);
          }
        });

        setHighlightedAbstract(newAbstract);
      } catch (error) {
        console.error("Error fetching highlight phrases:", error);
      } finally {
        setIsLoadingAbstract(false); // Stop loading
      }
    };

    if (
      currentArticle.pmid === pmid && // Only fetch if the article is used in the answer
      currentArticle.answer &&
      currentArticle.abstract
    ) {
      fetchHighlightPhrases();
    } else {
      // For similar articles, reset to unhighlighted abstract
      setHighlightedAbstract(currentArticle.abstract);
      setIsLoadingAbstract(false);
    }
  }, [
    currentArticle.pmid,
    currentArticle.answer,
    currentArticle.abstract,
    pmid, // Include pmid in dependencies
  ]);

  // ... rest of your component remains unchanged

  // Adjust popup positioning based on isSourceCollapsed
  const getPopupStyle = () => {
    if (isSourceCollapsed) {
      // Centered on screen
      return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      };
    } else {
      // Positioned between left edge and left edge of SourceSection
      const sourceSectionWidth = 300; // Assuming SourceSection width is 300px
      const windowWidth = window.innerWidth;
      const popupWidth = 600; // Max width as per className 'sm:max-w-[600px]'
      // Calculate the left position to center the popup between left edge and (windowWidth - sourceSectionWidth)
      const newLeft =
        (windowWidth - sourceSectionWidth - popupWidth) / 2 + popupWidth / 2;
      return {
        top: "50%",
        left: `${newLeft}px`,
        transform: "translate(-50%, -50%)",
      };
    }
  };

  return (
    <>
      <div
        ref={popupRef}
        className="bg-[#1F2937] text-white w-full sm:max-w-[600px] max-h-[85vh] overflow-y-auto rounded-lg shadow-lg p-6 relative flex flex-col"
        style={{
          position: "fixed",
          ...getPopupStyle(),
        }}
      >
        {sourceHistory.length > 0 ? (
          <button
            onClick={handleBack}
            className="absolute top-4 left-4 flex items-center text-white focus:outline-none bg-gray-700 hover:bg-gray-600 rounded-md p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span>Back</span>
          </button>
        ) : (
          <button
            onClick={closeSourcePopup}
            className="absolute top-4 left-4 flex items-center text-white focus:outline-none bg-gray-700 hover:bg-gray-600 rounded-md p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <span>Close</span>
          </button>
        )}

        {/* Increase margin-top to add space between buttons and title */}
        <div className="mt-12">
          <a
            href={pubmedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl font-semibold mb-2 hover:underline"
            style={{ color: "#ffc77d" }}
          >
            {currentArticle.title}
          </a>
        </div>

        <div className="space-y-4 flex-grow">
          <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
            <strong>Authors:</strong>
            <p>{currentArticle.authors}</p>
          </div>

          <div className="flex space-x-3">
            <div className="flex-1 p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
              <strong>Publication Date:</strong>
              <p>{currentArticle.publicationDate}</p>
            </div>
            <div className="flex-1 p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
              <strong>Publication Type:</strong>
              <p>
                {currentArticle.publicationType.length > 0
                  ? currentArticle.publicationType.join(", ")
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm relative">
            <strong>Abstract:</strong>
            {/* Move Spinner to Top-Right */}
            {isLoadingAbstract && (
              <div className="absolute top-2 right-2 flex items-center justify-center">
                <svg
                  className="animate-spin h-6 w-6 text-orange-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              </div>
            )}
            <p dangerouslySetInnerHTML={{ __html: highlightedAbstract }}></p>
          </div>

          <div
            className="p-4 bg-gray-700 rounded-lg shadow-inner cursor-pointer hover:bg-gray-600 transition-colors"
            onClick={handleCopy}
          >
            <strong style={{ color: "#ffc77d" }}>Citation (MLA):</strong>
            <div
              className="ml-4 text-sm whitespace-pre-wrap"
              style={{
                lineHeight: "2",
                textIndent: "-40px",
                paddingLeft: "40px",
              }}
            >
              <p>
                {currentArticle.authors}. “{currentArticle.title}.”
              </p>
              <p className="ml-6">
                <span className="italic">
                  {currentArticle.publicationType.length > 0
                    ? currentArticle.publicationType.join(", ")
                    : "N/A"}
                </span>
                , {currentArticle.publicationDate}.{" "}
                <span className="italic">PubMed</span>,{" "}
                <a href={pubmedUrl} target="_blank" rel="noopener noreferrer">
                  {pubmedUrl}
                </a>
                .
              </p>
            </div>
            <div className="text-xs mt-2 text-gray-400">
              {isCopied ? "Citation copied to clipboard!" : "Click to copy"}
            </div>
          </div>

          <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
            <strong>Similar Articles:</strong>
            {isLoadingSimilar ? (
              <div className="flex justify-center items-center mt-4">
                <svg
                  className="animate-spin h-8 w-8 text-orange-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              </div>
            ) : similarArticles && similarArticles.length > 0 ? (
              <SimilarArticlesList
                articles={similarArticles}
                updateSourcePopup={updateSourcePopup}
              />
            ) : (
              <p className="mt-2 text-gray-400">No similar articles found.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SourcePopup;
