// src/Controller.js
import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import InputSection from "./InputSection";

const Controller = forwardRef(
  ({ handleNewQuestion, updateResponse, isSourceCollapsed }, ref) => {
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const textareaRef = useRef(null);

    // Manage EventSource instances mapped by responseIndex
    const eventSourcesRef = useRef({});

    const fetchQuestionAnswer = useCallback(
      (question, responseIndex) => {
        const encodedQuery = encodeURIComponent(question);
        const eventSource = new EventSource(
          `${process.env.REACT_APP_API_URL}/question_answer_stream?query=${encodedQuery}`
        );

        // Store the EventSource
        eventSourcesRef.current[responseIndex] = eventSource;

        eventSource.onopen = () => {
          setIsLoading(true);
        };

        eventSource.onmessage = (event) => {
          updateResponse(responseIndex, event.data);
        };

        eventSource.addEventListener("sources", (event) => {
          try {
            const jsonData = JSON.parse(event.data);
            if (Array.isArray(jsonData)) {
              updateResponse(responseIndex, jsonData, true);
              setIsLoading(false);
              eventSource.close();
              delete eventSourcesRef.current[responseIndex];
            }
          } catch (error) {
            setIsError(true);
            setIsLoading(false);
            eventSource.close();
            delete eventSourcesRef.current[responseIndex];
          }
        });

        eventSource.onerror = (error) => {
          setIsError(true);
          setIsLoading(false);
          eventSource.close();
          delete eventSourcesRef.current[responseIndex];
        };
      },
      [updateResponse]
    );

    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLoading(true);
      if (query.trim()) {
        const newResponseIndex = handleNewQuestion(query);
        fetchQuestionAnswer(query, newResponseIndex);
        setQuery("");

        // Reset the textarea height to default after submission
        if (textareaRef.current) {
          textareaRef.current.style.height = "18px"; // Reset to default height
        }
      }
    };

    // Expose the redoQuestion function to parent components
    useImperativeHandle(ref, () => ({
      redoQuestion: (responseIndex) => {
        const existingEventSource = eventSourcesRef.current[responseIndex];
        if (existingEventSource) {
          existingEventSource.close();
          delete eventSourcesRef.current[responseIndex];
        }

        // Find the question corresponding to the responseIndex
        // This assumes that the parent component maintains the qaList and knows the question
        // We'll pass the question as a parameter from the parent during redo
      },
      fetchAnswer: (question, responseIndex) => {
        // Close existing EventSource if any
        const existingEventSource = eventSourcesRef.current[responseIndex];
        if (existingEventSource) {
          existingEventSource.close();
          delete eventSourcesRef.current[responseIndex];
        }

        // Re-issue the question
        fetchQuestionAnswer(question, responseIndex);
      },
    }));

    return (
      <div
        className="fixed bottom-6 z-50 transition-all duration-300 ease-in-out"
        style={{
          left: isSourceCollapsed ? "50%" : "37.5%", // Adjust based on source collapse state
          transform: "translateX(-50%)",
          width: "calc(100% - 2rem)",
          maxWidth: "600px",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <InputSection
          query={query}
          setQuery={setQuery}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          textareaRef={textareaRef} // Pass the shared ref
        />
      </div>
    );
  }
);

export default Controller;
