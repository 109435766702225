import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref for the dropdown

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  const handleSettingsClick = () => {
    navigate("/settings");
    setDropdownOpen(false); // Close the dropdown after navigation
  };

  const handleHomeClick = () => {
    navigate("/");
    setDropdownOpen(false); // Close the dropdown after navigation
  };

  return (
    <nav className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-20 items-center justify-between">
          <div className="flex flex-1 items-center justify-start">
            <div className="flex-shrink-0">
              <a
                href="https://www.askcureai.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="h-16 w-auto"
                  src="/assets/orange-trans.png"
                  alt="Your Company"
                />
              </a>
            </div>
          </div>
          <div className="flex items-center absolute inset-y-0 right-0 pr-2 sm:static sm:ml-6 sm:pr-0">
            <div className="relative" ref={dropdownRef}>
              <button
                id="dropdownMenuIconHorizontalButton"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                type="button"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 3"
                >
                  <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                </svg>
              </button>
              {dropdownOpen && (
                <div
                  id="dropdownDotsHorizontal"
                  className="absolute right-0 mt-2 w-44 py-2 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                >
                  <ul
                    className="text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownMenuIconHorizontalButton"
                  >
                    <li>
                      <button
                        onClick={handleHomeClick}
                        className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Home
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={handleSettingsClick}
                        className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Settings
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
